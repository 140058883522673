* {
   box-sizing: border-box;
}

html,
body {
    min-height: 100vh;
    font-size: 10px;
    padding: 0;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    overflow-x: hidden;
}

.container {
    width: 80%;
    margin: 4rem auto;
}

h1 {
    font-size: 4em;
    text-align: center;
    margin: 0;
}

.game-manager {
    position: relative;
    margin-top: 5em;
    display: flex;
    flex-direction: row;
}

@media screen and (min-width: 768px) {
    .game-manager {
        margin-top: 40em;
        width: 40vw;
        justify-content: end;
    }
}

.info {
    display: flex;
    flex-direction: column;
    align-items: start;
}

@media screen and (min-width: 768px) {
    .info {
        margin-right: 5rem;
    }
}

h2 {
    font-size: 3em;
    margin: 0;
}

p {
    font-size: 2em;
    margin: .75em 0;
}

a, button {
    font-size: 1.8em;
    cursor: pointer;
}

a {
    color: dodgerblue;
    margin-bottom: .75em;
}

button {
    background: dodgerblue;
    color: white;
    font-weight: bold;
    padding: .5em 1em;
    border: none;
    border-radius: 5px;
    margin-bottom: 2rem;
}

img {
    border-radius: 20px;
    height: 600px;
}

@media screen and (min-width: 768px) {
    img {
        height: 900px;
        position: absolute;
        left: 100%;
        top: calc(-900px/3)
    }
}

